import { Button } from "@/components/ui/button";

export function TablePopover({ editor }: any) {
  const rows = [1, 2, 3, 4, 5]; // Options for rows
  const cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; // Options for columns

  function handleClick(rowCount: number, colCount: number) {
    editor
      .chain()
      .focus()
      .insertTable({ rows: rowCount, cols: colCount, withHeaderRow: true })
      .run();
  }

  function deleteTable() {
    editor.chain().focus().deleteTable().run();
  }

  return (
    <div className="table-popover">
      <div className="p3-medium">Insert table</div>
      <table className="mt-2">
        <tbody>
          {rows.map((row) => (
            <tr key={row}>
              <td>
                {cols.map((col) => (
                  <button
                    className="cur h-8 w-8 border"
                    key={`${row}-${col}`}
                    onClick={() => handleClick(row, col)}
                  >
                    {col}
                  </button>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        onClick={deleteTable}
        variant="secondary"
        size="sm"
        className="mt-4"
      >
        Delete Table
      </Button>
    </div>
  );
}
