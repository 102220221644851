import { useState } from "react";

import { Loading } from "@/components/loading";
import { API_URL, handleError, superFetch } from "@/lib/utils";

interface FileUploadProps {
  onChange: (files: File[]) => void;
  children?: React.ReactNode;
  id?: string;
}

export function FileUploadDropZone(props: FileUploadProps) {
  const [isDragOver, setIsDragOver] = useState(false);

  return (
    <div
      className="flex items-center justify-center"
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setIsDragOver(false);
      }}
      onDrop={async (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const fileList = e.dataTransfer.files;
        if (fileList) {
          try {
            const files = Array.from(fileList);
            const res = (await uploadImageBatch(files)) as any;
            props.onChange(Object.values(res?.data?.urls || []));
          } catch (error) {
            handleError(error);
          }
        }
      }}
    >
      <label
        className={`${
          isDragOver ? "bg-primary" : "bg-coffee-50"
        } flex h-full w-full cursor-pointer items-center justify-center rounded`}
        id={props.id}
      >
        {props.children}
        <input
          type="file"
          className="hidden"
          onChange={async (e) => {
            const fileList = e.target.files;
            if (fileList) {
              try {
                const files = Array.from(fileList);
                const res = (await uploadImageBatch(files)) as any;
                props.onChange(Object.values(res?.data?.urls || []));
              } catch (error) {
                handleError(error, "Unable to upload image");
              }
            }
          }}
          onClick={(e) => {
            e.currentTarget.value = "";
          }}
          multiple
        />
      </label>
    </div>
  );
}

interface AttachmentUploadProps {
  onChange: (data: any) => void;
  children?: React.ReactNode;
  type?: string;
  fileTypes?: string[];
}

export function AttachmentUploadDropZone(props: AttachmentUploadProps) {
  const [isDragOver, setIsDragOver] = useState(false);
  const [busy, setBusy] = useState(false);

  return (
    <div
      className="flex items-center justify-center"
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setIsDragOver(false);
      }}
      onDrop={async (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const fileList = e.dataTransfer.files;
        if (fileList) {
          const files = Array.from(fileList);
          const res = (await uploadFiles(files)) as any;
          props.onChange(Object.values(res?.data?.urls || []));
        }
      }}
    >
      <label
        className={`${
          isDragOver ? "bg-primary" : "bg-coffee-50"
        } flex h-full w-full cursor-pointer items-center justify-center rounded`}
      >
        {!busy ? (
          <>
            {props.children}

            <input
              type="file"
              className="hidden"
              onChange={async (e) => {
                const fileList = e.target.files;
                if (fileList) {
                  try {
                    setBusy(true);
                    const files = Array.from(fileList);
                    if (props.type === "document") {
                      const res = await uploadDocuments(files);
                      props.onChange(res.data[0]);
                    } else {
                      const res = await uploadFiles(files);
                      const file_name = Object.keys(res.data.urls)[0];
                      const url = res.data.urls[file_name];
                      props.onChange({ file_name, url });
                    }
                  } catch (error) {
                    handleError(error, "Unable to upload file");
                  } finally {
                    setBusy(false);
                  }
                }
              }}
              onClick={(e) => {
                e.currentTarget.value = "";
              }}
              multiple
            />
          </>
        ) : (
          <Loading />
        )}
      </label>
    </div>
  );
}

export function uploadImageBatch(files: File[]) {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("file", file, file.name);
  });
  return superFetch(`${API_URL}/users/upload_image_batch `, {
    method: "POST",
    body: formData,
  }) as any;
}

function uploadFiles(files: File[]) {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("file", file, file.name);
  });
  return superFetch(`${API_URL}/users/upload_files `, {
    method: "POST",
    body: formData,
  }) as any;
}

function uploadDocuments(files: File[]) {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("file", file, file.name);
  });
  return superFetch(`${API_URL}/users/upload_user_documents `, {
    method: "POST",
    body: formData,
  }) as any;
}

export function AttachmentDropZone(props: AttachmentUploadProps) {
  const [isDragOver, setIsDragOver] = useState(false);

  function handleFile(fileList: FileList) {
    try {
      const file = Array.from(fileList)[0];
      if (
        props.fileTypes &&
        props.fileTypes.includes(file.name.split(".").pop() || "")
      ) {
        props.onChange(file);
      } else {
        handleError(`Only allow ${props.fileTypes?.join(", ")} file types`);
      }
    } catch (error) {
      handleError(error, "Unable to load file");
    }
  }

  return (
    <div
      className="flex items-center justify-center"
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setIsDragOver(false);
      }}
      onDrop={async (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const fileList = e.dataTransfer.files;
        if (fileList) {
          handleFile(fileList);
        }
      }}
    >
      <label
        className={`${
          isDragOver ? "bg-primary" : "bg-coffee-50"
        } flex h-full w-full cursor-pointer items-center justify-center rounded`}
      >
        <>
          {props.children}

          <input
            type="file"
            className="hidden"
            onChange={async (e) => {
              const fileList = e.target?.files;
              if (fileList) {
                handleFile(fileList);
              }
            }}
            onClick={(e) => {
              e.currentTarget.value = "";
            }}
            multiple
          />
        </>
      </label>
    </div>
  );
}
