import {
  Bold,
  Heading1,
  Heading2,
  Heading3,
  Italic,
  Link,
  List,
  ListOrdered,
  Table,
  Underline,
  WrapText,
} from "lucide-react";
import { useCallback } from "react";

import { TablePopover } from "@/components/editor/table-popover";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Toggle } from "@/components/ui/toggle";

interface Props {
  editor: any;
}

export const MenuBar = ({ editor }: Props) => {
  const setLink = useCallback(() => {
    if (!editor) return;
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="flex h-14 w-full items-center justify-between border-b border-b-coffee-500 px-5 !text-black">
      <Toggle
        size="sm"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "bg-slate-300" : ""}
        aria-label="Toggle bold"
      >
        <Bold size={20} />
      </Toggle>
      <Toggle
        size="sm"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "bg-slate-300" : ""}
        aria-label="Toggle italic"
      >
        <Italic size={20} />
      </Toggle>
      <Toggle
        size="sm"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        className={editor.isActive("underline") ? "bg-slate-300" : ""}
        aria-label="Toggle underline"
      >
        <Underline size={20} />
      </Toggle>
      <Toggle
        size="sm"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={
          editor.isActive("heading", { level: 1 }) ? "bg-slate-300" : ""
        }
        aria-label="Toggle heading 1"
      >
        <Heading1 size={20} />
      </Toggle>
      <Toggle
        size="sm"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={
          editor.isActive("heading", { level: 2 }) ? "bg-slate-300" : ""
        }
        aria-label="Toggle heading 2"
      >
        <Heading2 size={20} />
      </Toggle>
      <Toggle
        size="sm"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={
          editor.isActive("heading", { level: 3 }) ? "bg-slate-300" : ""
        }
        aria-label="Toggle heading 3"
      >
        <Heading3 size={20} />
      </Toggle>
      <Toggle
        size="sm"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "bg-slate-300" : ""}
        aria-label="Toggle bullet list"
      >
        <List size={20} />
      </Toggle>
      <Toggle
        size="sm"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "bg-slate-300" : ""}
        aria-label="Toggle ordered list"
      >
        <ListOrdered size={20} />
      </Toggle>
      <Toggle
        size="sm"
        onClick={() => editor.chain().focus().setHardBreak().run()}
        aria-label="Toggle hard break"
      >
        <WrapText size={20} />
      </Toggle>
      <Toggle
        size="sm"
        onClick={setLink}
        className={editor.isActive("link") ? "bg-slate-300" : ""}
        aria-label="Toggle link"
      >
        <Link size={20} />
      </Toggle>
      <Popover>
        <PopoverTrigger>
          <Button variant="ghost">
            <Table size={20} />
          </Button>
          <PopoverContent className="w-fit">
            <TablePopover editor={editor} />
          </PopoverContent>
        </PopoverTrigger>
      </Popover>
    </div>
  );
};
