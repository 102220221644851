import TipTapLink from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";

const truncate = function (string: string, max: number, suffix: string) {
  if (string.length < max) {
    return string;
  }

  const lastSpaceIndex = string
    .substring(0, max - suffix.length)
    .lastIndexOf(" ");

  return `${string.substring(0, lastSpaceIndex)}${suffix}`;
};

export function renderFirstParagraph(el: any) {
  if (!el) return "";

  if (el.type === "text") {
    return truncate(el.text, 90, "...");
  }

  if (["paragraph", "doc", "list"].includes(el.type)) {
    return renderFirstParagraph(el.content?.[0]);
  }

  return "";
}

export const TIPTAP_EXTENSIONS = [
  StarterKit,
  TipTapLink,
  Underline,
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
];

export function fixLink(link: string) {
  if (!link) return link;
  if (link.length === 0) return link;
  if (!link.startsWith("http://") && !link.startsWith("https://")) {
    link = "https://" + link;
  }
  return link;
}
