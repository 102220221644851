import * as React from "react";

import { useFormField } from "@/components/ui/form";
import { cn } from "@/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "p3-medium placeholder:p3 flex h-10 w-full rounded border border-coffee-500 bg-interface-white px-3 py-3 text-sm font-medium text-coffee-600 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-medium placeholder:text-coffee-500 focus-visible:border focus-visible:border-input focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:!border-coffee-300 disabled:!text-coffee-400 [&:not(:placeholder-shown)]:border [&:not(:placeholder-shown)]:border-coffee-600 [&:not(:placeholder-shown)]:bg-white [&:not(:placeholder-shown)]:text-coffee-900",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

const FormInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const { error } = useFormField();
    return (
      <input
        type={type}
        className={cn(
          "placeholder:p3 p3-medium flex w-full rounded border border-coffee-500 bg-interface-white px-3 py-3 font-medium text-coffee-600 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-coffee-500 focus-visible:border focus-visible:border-input focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed  disabled:!border-coffee-300 disabled:!text-coffee-400 [&:not(:placeholder-shown)]:border [&:not(:placeholder-shown)]:border-coffee-600 [&:not(:placeholder-shown)]:bg-coffee-50 [&:not(:placeholder-shown)]:text-coffee-900",
          error && "border border-error text-error",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
FormInput.displayName = "FormInput";

export { FormInput, Input };
