import * as React from "react";

import { cn } from "@/lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "p2-medium placeholder:p2 flex min-h-[80px] w-full rounded-md border border-coffee-500 bg-background px-3 py-2 ring-offset-background placeholder:text-coffee-500 focus-visible:border-input focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 [&:not(:placeholder-shown)]:bg-coffee-50 [&:not(:placeholder-shown)]:text-coffee-900",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
