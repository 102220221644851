import { ReloadIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";

export function Loading({ className }: { className?: string }) {
  return (
    <div className={cn("flex items-center justify-center p-4", className)}>
      <ReloadIcon className="h-4 w-4 animate-spin" />
    </div>
  );
}
