import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import { useEffect } from "react";

import { MenuBar } from "@/components/editor/menubar";
import { TIPTAP_EXTENSIONS } from "@/lib/text";

interface EditorProps {
  initialValue: string;
  onChange: (value: any) => void;
  editorClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
}

export function TiptapEditor(props: EditorProps) {
  const tipTapEditor = useEditor({
    extensions: [
      ...TIPTAP_EXTENSIONS,
      Placeholder.configure({
        placeholder: props.placeholder,
      }),
    ],
    content: props.initialValue,
    onUpdate: ({ editor }) => {
      const json = editor.getJSON();
      props.onChange(json);
    },
    editorProps: {
      attributes: {
        class: `overflow-auto prose m-1 focus:outline-none ${
          props.editorClassName
        } ${props.disabled ? "bg-interface-grey" : ""}}`,
      },
    },
    editable: !props.disabled,
  });

  useEffect(() => {
    props.disabled !== undefined && tipTapEditor?.setEditable(!props.disabled);
  }, [props.disabled, tipTapEditor]);

  return (
    <div className="relative rounded-md border border-coffee-500 bg-white text-base">
      <MenuBar editor={tipTapEditor} />
      <div className="p-3 pb-12">
        <EditorContent
          editor={tipTapEditor}
          className={props.editorClassName}
          disabled={props.disabled}
          id={props.id}
        />
      </div>
    </div>
  );
}
